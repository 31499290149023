import { FunctionComponent, useState, useEffect, useRef } from "react";
import ApiContext from "../../context/api";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import "../../style/reset.scss";
import "../../style/index.scss";

interface MainAppProps {
	basename: string;
	overseer: any;
}

const MainApp: FunctionComponent<MainAppProps> = (props) => {

	return (
		//@ts-ignore
		<ApiContext.Provider value={props.api}>
			<Provider store={props.overseer.store}>
				<BrowserRouter>
					<div style={{position:"absolute",zIndex: 999999999}}>
						<ToastContainer />
					</div>
						<div id="@ledr/login"></div>
						<div id="@ledr/navmenu"></div>
						<div id="@ledr/browser"></div>
						<div id="@ledr/settings"></div>
						<div id="@ledr/tuto"></div>
				</BrowserRouter>
	
			</Provider>
		</ApiContext.Provider>
	);
};

export default MainApp;
