import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
//import Root from "./root.component";
import Root from "./containers/Layout/App";
import { toast } from "react-toastify";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    console.log({ err, info, props });
    return (
      <div style={{ backgroundColor: "red" }}>
        <h1>{err.message}</h1>
        {info.componentStack.split("\n").map((e, i) => (
          <React.Fragment key={i}>
            {e} <br />
          </React.Fragment>
        ))}
      </div>
    );
  },
});

export const { bootstrap, mount, unmount } = lifecycles;

export const notif = {
  info: (msg) => toast.info(msg, { position: toast.POSITION.TOP_CENTER }),
  warning: (msg) => toast.warning(msg, { position: toast.POSITION.TOP_CENTER }),
  success: (msg) => toast.success(msg, { position: toast.POSITION.TOP_CENTER }),
  error: (msg) => toast.error(msg, { position: toast.POSITION.TOP_CENTER }),
};
